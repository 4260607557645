<template>
    <div class="manage">
        <!-- 表单数据弹窗 -->
        <el-dialog :visible.sync="formDataDialogVisible" width="50%" title="查看用户信息">
            <!-- 表单数据描述列表 -->
            <el-descriptions>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        姓名
                    </template>
                    <el-tag size="small">{{ tableData.name }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-s-custom"></i>
                        性别
                    </template>
                    <el-tag size="small">{{ tableData.sex === 1 ? '男' : '女' }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-s-check"></i>
                        年龄
                    </template>
                    <el-tag size="small">{{ tableData.age }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        出生日期
                    </template>
                    <el-tag size="small">{{ tableData.birth }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        地址
                    </template>
                    <el-tag size="small">{{ tableData.addr }}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>


        <el-dialog title="新增用户" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
            <!-- 用户的表单信息 -->
            <el-form :model="form" ref="form" label-width="120px" :inline="true" :rules="rules">
                <el-form-item label="姓名:" prop="name">
                    <el-input v-model="form.name" placeholder="请输入姓名" clearable></el-input>
                </el-form-item>

                <el-form-item label="年龄:" prop="age">
                    <el-input v-model="form.age" placeholder="请输入年龄" clearable></el-input>
                </el-form-item>

                <el-form-item label="性别:" prop="sex">
                    <el-select v-model="form.sex" placeholder="--请选择--" clearable>
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="0"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="出生日期:" prop="birth">
                    <el-date-picker v-model="form.birth" type="date" placeholder="选择日期" value-format="yyyy-MM-DD">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="地址:" prop="addr">
                    <el-input v-model="form.addr" placeholder="请输入地址" clearable></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button @click="resetForm">重置</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 搜索..区域 -->
        <div class="manage-header">
            <el-button type="primary" plain size="small" @click="handleAdd" icon="el-icon-plus">新增</el-button>

            <el-form :model="userForm" ref="userForm" :inline="true">
                <el-form-item>
                    <el-input placeholder="请输入姓名..." v-model="userForm.name" clearable size="small"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery" size="small">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-refresh" @click="Pchongzhi" size="small">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" plain icon="el-icon-download" @click="handleExport"
                        size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 列表字段显示 -->
        <div class="common-table">
            <el-table :data="tableData" border style="width: 100%" max-height="400px" v-loading="loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column fixed prop="name" label="姓名">
                </el-table-column>
                <el-table-column prop="sex" label="性别">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.sex === 1 ? '男' : '女' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="age" label="年龄">
                </el-table-column>
                <el-table-column prop="birth" label="出生日期">
                </el-table-column>
                <el-table-column prop="addr" label="地址">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="auto">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="handleCheck(scope.row)">查看</el-button>
                        <el-button type="info" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="paper">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :total="total"
                    :page-sizes="[10, 20, 30, 40]" :page-size="100" @current-change="handlePage">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getUser, addUser, editUser, delUser } from '@/api'
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import { saveAs } from 'file-saver';

export default {
    data() {
        return {
            dialogVisible: false,
            formDataDialogVisible: false,
            selectedRows: [], // 用于存储选中的行
            form: {
                name: '',
                age: '',
                sex: '',
                birth: '',
                addr: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                age: [
                    { required: true, message: '请输入年龄', trigger: 'blur' }
                ],
                sex: [
                    { required: true, message: '请输入性别', trigger: 'blur' }
                ],
                birth: [
                    { required: true, message: '请输入出生日期', trigger: 'blur' }
                ],
                addr: [
                    { required: true, message: '请输入地址', trigger: 'blur' }
                ]

            },
            tableData: [],
            loading: true,
            modalType: 0, // 0表示新增的弹窗，1表示编辑
            total: 0,
            pageData: {
                page: 1,
                limit: 10
            },
            userForm: {
                name: '',
            }
        }
    },
    methods: {
        handleExport() {
            // 1.导出数据，需要将数据先获取到
            // 2.将数据转成excel表格
            // 3.将excel表格下载到本地
            // 4.需要下载的excel表格，需要以二进制流的形式进行下载

            // 1. 获取数据
            const data = this.tableData;

            // 2. 将数据转成 Excel 表格
            if (!XLSX || !XLSX.utils) {
                console.error('XLSX 库未正确加载');
                return;
            }

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // 3. 将 Excel 表格转换为二进制流
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });


            // 4. 将二进制流下载到本地
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'users.xlsx');

        },
        handleSelectionChange(selection) {
            this.selectedRows = selection;
            console.log('选中的行:', this.selectedRows);
        },
        // 提交表单信息
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.modalType === 0) {
                        addUser(this.form).then(() => {
                            // 重新获取列表的接口
                            this.getList()
                        })
                    } else {
                        editUser(this.form).then(() => {
                            // 重新获取列表的接口
                            this.getList()
                        })
                    }

                    // 清空表单的数据
                    this.$refs.form.resetFields()

                    // 成功之后、关闭弹窗
                    this.dialogVisible = false
                }
            })
        },
        // 弹窗关闭的时候
        handleClose() {
            this.$refs.form.resetFields()
            this.dialogVisible = false
        },
        cancel() {
            this.handleClose()
        },
        resetForm() {
            this.$refs.form.resetFields()
        },
        handleCheck(data) {
            this.formDataDialogVisible = true
            // this.tableData = Object.assign({},data)
            this.tableData = JSON.parse(JSON.stringify(data))
        },
        handleEdit(row) {
            this.modalType = 1
            this.dialogVisible = true
            // 注意需要对当前行数据进行深拷贝
            this.form = JSON.parse(JSON.stringify(row))
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delUser({ id: row.id }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getList()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleAdd() {
            this.modalType = 0
            this.dialogVisible = true
        },
        Pchongzhi() {
            // console.log(val,'val')
            this.userForm.name = '';
        },
        handleQuery() {
            this.getList()
        },
        getList() {
            // 获取列表的数据
            getUser({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                console.log(data)
                this.tableData = data.list
                this.total = data.count || 0
            })
        },
        handlePage(val) {
            // console.log(val, 'val')
            this.pageData.page = val
            this.getList()
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 3000),
            this.getList()
    }
}
</script>

<style lang="less" scoped>
.manage {
    height: 95%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: -12px;
    }

    .common-table {
        position: relative;
        height: calc(100% - 65px);

        .paper {
            position: absolute;
            // display: flex;
            bottom: 0;
            right: 20px;
            margin-bottom: 180px;
        }
    }


}
</style>
<template>
    <h2>管理登录账号页面</h2>
</template>

<script>
export default {
    name: 'Account',
    data() {
        return {

        }

    }
}
</script>


<style></style>
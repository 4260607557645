import request from '@/utils/request';


// 查询列表
export const getChanelList = (val) => {
    return request.post('/gateway/findPage',  val );
};

/** 根据id查询网关数据列表 */
export const chanelId = (id) => {
    return request.get(`/gateway/findById/${id}`);
};


// 新增列表
export const chanelAdd = (val) => {
    return request.post('/gateway/batchInsert', val);
};

// 根据id进行删除列表
// export const chanelDelete = (id) => {
//     return request.delete(`/gateway/delete/${id}`);
// };

// 修改列表
// export const chanelUpdate = (val) => {
//     return request.put('/gateway/update', val);
// };



// 批量删除
// export const deleteAll = (id) => {
//     return request.delete(`/gateway/delete/${id}`,{
//     })
// };


// 更改密码
// export const chanelPass = (val) => {
//     return request.put('/gateway/pass', val);
// };
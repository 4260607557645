import http from "../utils/request";


export const getUser = (params) => {
    console.log(params, 'params')
    return http.get('/user/getUser', params)
}


export const addUser = (data) => {
    return http.post('/user/add', data)
}

export const editUser = (data) => {
    return http.post('/user/edit', data)
}


export const delUser = (data) => {
    return http.post('/user/del', data)
}

export const getMenu = (data) => {
    return http.post('/permission/getMenu', data)
}
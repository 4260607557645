<template>
    <div class="gateway">
        <!-- 新增/编辑通道弹窗 -->
        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="850px" :before-close="handleClose" :title="title">
            <el-form ref="ruleForm" :rules="rules" :model="form" label-width="120px" class="form-container">
                <el-tabs type="card" v-model="activeTab" @tab-click="handleTabClick" class="tabs-container">
                    <!--基础信息-->
                    <el-tab-pane label="基本信息" name="basicInfo">
                        <el-col :span="12">
                            <el-form-item label="id" prop="id" v-if="false">
                                <el-input 
                                    v-model="form.id" 
                                    placeholder="请输入ID名称" 
                                    style="width: 220px;"
                                    clearable
                                    disabled>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="网关ID" prop="gatewayId">
                                    <el-input 
                                        v-model="form.gatewayId" 
                                        placeholder="请输入网关ID" 
                                        style="width: 220px;"
                                        clearable 
                                        disabled>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="网关名称" prop="gatewayName">
                                    <el-input 
                                        v-model="form.gatewayName" 
                                        placeholder="请输入网关名称" 
                                        style="width: 220px;"
                                        clearable 
                                        disabled>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="网关类型" prop="gatewayType">
                                    <el-select 
                                        v-model="form.gatewayType" 
                                        placeholder="---请选择---" 
                                        style="width: 220px;"
                                        clearable 
                                        disabled>
                                        <el-option 
                                            v-for="item in gatewayTypeOptions" 
                                            :key="item.value"
                                            :label="item.label" 
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="支持类型" prop="payType">
                                    <el-select 
                                        v-model="form.payType" 
                                        placeholder="---请选择---" 
                                        style="width: 220px;"
                                        clearable 
                                        disabled>
                                        <el-option
                                            v-for="item in payTypeOptions" 
                                            :key="item.value" 
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>

                    <!--参数设置信息-->
                    <el-tab-pane label="参数设置" name="collectionParams">
                        <el-form-item label="接口类型" style="margin-left: -38px">
                            <el-select 
                                v-model="selectedOption" 
                                placeholder="---请选择---" 
                                @change="handleOptionChange" 
                                :disabled="isReadOnly">
                                <el-option 
                                    v-for="option in gatewaytableType" 
                                    :key="option.key" 
                                    :label="option.label"
                                    :value="option.value" 
                                    :disabled="isOptionDisabled(option.value)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-card v-for="(data, index) in form.collectionParams" :key="index" class="box-card">
                            <div slot="header" class="clearfix">
                                <span style="font-weight: bold; font-size: 16px">
                                    {{ getGatewayLabel(data[0].interfaceType) }}
                                </span>
                                <el-button 
                                    style="float: right; padding: 3px 0" type="text"
                                    @click="addCollectionParam(index)" 
                                    :disabled="isReadOnly">
                                    添加行
                                </el-button>
                                <el-button style="float: right; padding: 3px 0; margin-right: 15px;" type="text"
                                    @click="removeTable(index)" 
                                    :disabled="isReadOnly">
                                    删除
                                </el-button>
                            </div>
                            <el-table :data="data" style="width: 100%" border>
                                <el-table-column prop="code" label="Code" width="200">
                                    <template #default="{ row }">
                                        <el-input 
                                            v-model="row.code" 
                                            placeholder="请输入参数代码" 
                                            clearable
                                            :disabled="isReadOnly">
                                        </el-input>
                                        <!-- || disabledCode -->
                                    </template>
                                </el-table-column>
                                <el-table-column prop="value" label="Value" width="150"> 
                                    <template #default="{ row }">
                                        <el-input 
                                            v-model="row.value" 
                                            placeholder="请输入参数值" 
                                            clearable
                                            :disabled="isReadOnly">
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="location" label="Location" width="200">
                                    <template #default="{ row }">
                                        <el-select 
                                            v-model="row.location" 
                                            placeholder="--请选择存放位置--" 
                                            clearable
                                            :disabled="isReadOnly">
                                            <!-- || disabledLocation -->
                                                <el-option 
                                                    v-for="locOption in locationOptions" 
                                                    :key="locOption.value"
                                                    :label="locOption.label" 
                                                    :value="locOption.value">
                                                </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="150">
                                    <template slot-scope="scope">
                                        <el-button 
                                            :icon="data.length === 1 ? 'el-icon-warning' : 'el-icon-delete'"
                                            :type="data.length === 1 ? 'info' : 'text'"
                                            @click="removeCollectionParam(index, scope.$index)" 
                                            size="small"
                                            :disabled="isReadOnly">
                                        </el-button>
                                        <el-button 
                                            icon="el-icon-plus" 
                                            @click="addCollectionParam(index, scope.$index)"
                                            type="text" 
                                            size="small" 
                                            :disabled="isReadOnly">
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-tab-pane>

                    <!--接口设置信息-->
                    <el-tab-pane label="接口设置" name="paymentParams">
                        <el-form-item label="接口类型" style="margin-left: -38px;">
                            <el-select 
                                v-model="selectedInterfaceType" 
                                placeholder="---请选择---"
                                @change="handleInterfaceTypeChange" 
                                :disabled="isReadOnly">
                                <el-option v-for="option in interfaceTypes" 
                                    :key="option.value" 
                                    :label="option.label"
                                    :value="option.value" 
                                    :disabled="OptionDisabled(option.value)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-card v-for="(interfaceData, index) in form.paymentParams" :key="index" class="box-card">
                            <div slot="header" class="clearfix">
                                <span style="font-weight: bold;font-size: 16px">
                                    {{getInterfaceLabel(interfaceData.interfaceType) }}
                                </span>
                                <el-button style="float: right; padding: 3px 0" type="text"
                                    @click="removeInterface(index)" 
                                    :disabled="isReadOnly">删除
                                </el-button>
                            </div>
                            <el-form  label-width="80px" :model="interfaceData" :rules="rules">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="接口地址" prop="interfaceUrl">
                                            <el-input 
                                                v-model="interfaceData.interfaceUrl" 
                                                placeholder="请输入接口地址"
                                                style="width: 290px;" 
                                                clearable 
                                                :disabled="isReadOnly">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="请求方式" prop="httpMethod">
                                            <el-select 
                                                v-model="interfaceData.httpMethod" 
                                                placeholder="---请选择---"
                                                style="width: 290px;" 
                                                clearable 
                                                :disabled="isReadOnly">
                                                    <el-option 
                                                        v-for="method in httpMethods" 
                                                        :key="method.value"
                                                        :label="method.text" 
                                                        :value="method.value">
                                                    </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="参数类型" prop="contentType">
                                            <el-select 
                                                v-model="interfaceData.contentType" 
                                                placeholder="---请选择---"
                                                style="width: 290px;" 
                                                clearable 
                                                :disabled="isReadOnly">
                                                    <el-option 
                                                        v-for="type in contentTypes" 
                                                        :key="type.value"
                                                        :label="type.text" 
                                                        :value="type.value">
                                                    </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="同步方式" prop="httpAsyn">
                                            <el-select 
                                                v-model="interfaceData.httpAsyn" 
                                                placeholder="---请选择---"
                                                style="width: 290px;" 
                                                clearable 
                                                :disabled="isReadOnly">
                                                    <el-option 
                                                        v-for="syncType in syncTypes" 
                                                        :key="syncType.value"
                                                        :label="syncType.text" 
                                                        :value="syncType.value">
                                                    </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="接口入参" prop="reqJson">
                                            <el-input 
                                                type="textarea" 
                                                :rows="6" 
                                                v-model="interfaceData.reqJson"
                                                placeholder="请输入接口入参" 
                                                style="width: 290px;" 
                                                clearable
                                                :disabled="isReadOnly">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="同步响应" prop="resSyncJson">
                                            <el-input 
                                                type="textarea" 
                                                :rows="6" 
                                                v-model="interfaceData.resSyncJson"
                                                placeholder="请输入同步接口出参" 
                                                style="width: 290px;" clearable
                                                :disabled="isReadOnly">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="异步回调" prop="resAsyncJson">
                                            <el-input 
                                                type="textarea" 
                                                :rows="6" 
                                                v-model="interfaceData.resAsyncJson"
                                                placeholder="请输入异步接口出参" 
                                                style="width: 290px;" 
                                                clearable
                                                :disabled="isReadOnly">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <!-- 动态添加 token 存储位置 -->
                                <el-row v-if="interfaceData.interfaceType === '6'|| interfaceData.interfaceType==='7'">
                                    <el-col :span="12">
                                        <el-form-item label="token存放位置" prop="location">
                                            <el-select 
                                                v-model="interfaceData.location" 
                                                placeholder="---请选择---"
                                                style="width: 290px;" 
                                                clearable 
                                                :disabled="isReadOnly">
                                                    <el-option 
                                                        v-for="option in tokenStorageOptions" 
                                                        :key="option.value"
                                                        :label="option.label" 
                                                        :value="option.value">
                                                    </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-card>
                    </el-tab-pane>
                </el-tabs>

                <!-- [取消，确认按钮] -->
                <span v-if="shouldShowButtons()" class="dialog-footer" style="margin-left: 655px;">
                    <el-button @click="cancel">取 消</el-button>
                    <!-- :loading="submitLoading" -->
                    <el-button type="primary" @click="submit"  :disabled="isReadOnly">确认</el-button>
                </span>
            </el-form>
        </el-dialog>


        <!-- 搜索区域 -->
        <div class="manage-header">
            <el-form :model="queryVo" ref="queryParams" :inline="true">
                <el-form-item label="网关名称" style="font-weight: bold;">
                    <el-input 
                        placeholder="请输入网关名称" 
                        v-model="queryVo.gatewayName" 
                        clearable size="small">
                    </el-input>
                </el-form-item>
                <el-form-item label="支持类型" style="font-weight: bold;">
                    <el-select 
                        v-model="queryVo.payType" 
                        placeholder="--请选择支持类型--" 
                        clearable 
                        size="small">
                            <el-option value="in" label="代收"></el-option>
                            <el-option value="out" label="代付"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery" size="mini">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-refresh" @click="resetQuery" size="mini">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="buttons-container">
                <el-button type="success" plain  size="mini" icon="el-icon-edit" @click="handleUpdate" :disabled="single">修改</el-button>
                <el-button type="primary" plain  size="mini" icon="el-icon-download" @click="handleExport">导出</el-button>
            </div>
        </div>

        <!-- 列表字段显示 -->
        <div class="common-table">
            <el-table 
                :data="gatewayList" 
                v-loading="loading" 
                @selection-change="handleSelectionChange" 
                fit 
                stripe
            >
                <el-table-column type="selection" width="55" align="center" />
                <el-table-column label="序号" align="center" prop="id" width="230px" />
                <el-table-column label="网关ID" align="center" prop="gatewayId" width="230px" />
                <el-table-column label="网关名称" align="center" prop="gatewayName" width="230px" />
                <el-table-column label="网关类型" align="center" prop="gatewayType" width="230px">
                    <template #default="{ row }">
                        <span v-if="row.gatewayType === '1'">网关</span>
                        <span v-else>公户</span>
                    </template>
                </el-table-column>
                <el-table-column label="支持类型" align="center" prop="payType" width="230px">
                    <template #default="{ row }">
                        <span v-if="row.payType === 'in'">代收</span>
                        <span v-else>代付</span>
                    </template>
                </el-table-column>
                <!-- sortable :sort-method="customSortMethod" -->
                <el-table-column label="创建时间" align="center" prop="createTime" width="230px"  />
                <!-- <el-table-column label="修改时间" align="center" prop="updateTime" width="230px" /> -->
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button 
                            size="mini" 
                            icon="el-icon-zoom-out" 
                            type="text"
                            @click="readAll(scope.row)">
                            详细
                        </el-button>
                        <el-button 
                            type="text" 
                            icon="el-icon-edit" 
                            size="mini"
                            @click="handleUpdate(scope.row)">
                            修改
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页列表 -->
        <div class="paper">
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" 
                :total="total"
                :page-sizes="[10, 20, 30, 40]" 
                :page-size="pageSize" 
                @current-change="handlePage"
                @size-change="handleSizeChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import { saveAs } from 'file-saver';
// chanelDelete, chanelUpdate,deleteAll---暂不需要
import { getChanelList, chanelId, chanelAdd } from '@/api/channel'

export default {
    data() {
        return {
            initialForm: {}, // 保存初始数据
            /**  置灰属性
            gatewayData: [],
            disabledCode: false,
            disabledLocation: false,*/
            isReadOnly: false,      // 标志是否只读
            dialogTableVisibled: false, //是否显示详情对话框
            loading: false, // 加载状态
            // submitLoading: false, // 提交按钮的加载状态
            // selectedRow: null, // 当前选中的行
            // selectedRows: [], // 用于存储选中的行
            single: true,       // 非单个禁用
            reasonable: true,
            interfaces: [],      // 用于存储接口类型
            tableData: [],       // 用于存储网关数据
            dialogVisible: false, // 是否显示增改对话框
            gatewayList: [], // 用于存储网关数据
            activeTab: 'basicInfo', // 初始化为 'basicInfo'
            title: "", // 确保 title 已经初始化
            selectedOption: '',   // 用于存储网关下拉框选中的值
            InterfaceOption: null, // 用于存储接口下拉框选中的值
            size: '',
            data: [],       // 用于存储网关数据
            interfaceData: [],   // 用于存储接口数据
            selectedInterfaceType: '',   // 用于存储选中的接口类型
            interfaceTypes: [],  // 用于存储接口类型
            total: 0,
            ids: [],        // 选中数组
            isAsc: "",
            orderByColumn: "",
            selectedGateway: {},
            pageNum: 1,
            pageSize: 10,
            form: {
                id: '',
                gatewayId: '',
                gatewayName: '',
                gatewayType: '',
                payType: '',
                collectionParams: [],
                paymentParams: [],
            },
            rules: {
                interfaceUrl: [
                    { required: true, message: '请输入接口地址', trigger: 'blur' }
                ],
                httpMethod: [
                    { required: true, message: '请选择请求方式', trigger: 'blur' }
                ],
                contentType: [
                    { required: true, message: '请选择参数类型', trigger: 'blur' }
                ],
                httpAsyn: [
                    { required: true, message: '请选择同步方式', trigger: 'blur' }
                ],
                reqJson: [
                    { required: true, message: '请输入接口入参', trigger: 'blur' }
                ],
                resSyncJson: [
                    { required: true, message: '请输入同步接口出参', trigger: 'blur' }
                ],
                location: [
                    { required: true, message: '请输入同步接口出参', trigger: 'blur' }
                ],
                // value: [
                //     { required: true, message: '请输入参数值', trigger: 'blur' },
                //     { min: 5, message: '参数值长度至少为5', trigger: 'blur' }
                // ]

            },
            /** 页面搜索按钮字段 */
            queryVo: {
                gatewayName: "",
                payType: ""
            },
            tokenStorageOptions: [
                { value: '1', label: 'head' },
                { value: '2', label: 'body' },
                { value: '3', label: 'url后面' }
            ],
            locationOptions: [
                { value: '1', label: 'head' },
                { value: '2', label: 'body' },
                { value: '3', label: 'url后面' }
            ],
            /** 网关类型名称 */
            gatewayTypeOptions: [
                { value: '1', label: '网关' },
                { value: '2', label: '公户' },
            ],
            payTypeOptions: [
                { value: 'in', label: '代收' },
                { value: 'out', label: '代付' },
            ],
            /** 参数设置下拉框选择 */
            gatewaytableType: [
                { key: '1', label: '代收', value: '1',interfaceType: "1"},
                { key: '2', label: '代付', value: '2',interfaceType: "2"},
                { key: '3', label: '代收状态查询', value: '3',interfaceType: "3"},
                { key: '4', label: '代付状态查询', value: '4',interfaceType: "4"},
                { key: '5', label: '账户余额查询', value: '5',interfaceType: "5"},
                { key: '6', label: '请求网关token', value: '6',interfaceType: "6"},
                { key: '7', label: '请求订单ID', value: '7',interfaceType: "7"}
            ],
            interfaceTypes: [
                { value: '1', label: '代收',  interfaceType: "1", key: '1'},
                { value: '2', label: '代付',  interfaceType: "2", key: '2'},
                { value: '3', label: '代收状态查询', interfaceType: "3", key: '3'},
                { value: '4', label: '代付状态查询', interfaceType: "4" , key: '4'},
                { value: '5', label: '账户余额查询', interfaceType: "5" , key: '5'},
                { value: '6', label: '请求网关token',interfaceType: "6", key: '6'},
                { value: '7', label: '请求订单ID',interfaceType: "7", key: '7'},
            ],
            httpMethods: [
                { value: '1', text: 'GET' },
                { value: '2', text: 'POST' }
            ],
            contentTypes: [
                { value: '1', text: 'application/json' },
                { value: '2', text: 'application/x-www-form-urlencoded' },
                { value: '3', text: 'multipart/form-data' }
            ],
            syncTypes: [
                { value: '1', text: '同步' },
                { value: '2', text: '异步' }
            ]
        };
    },
    created() {
        this.getList();
         
        // 初始化组件时禁用已有的接口类型
        this.form.paymentParams.forEach(param => {
            const option = this.interfaceTypes.find(opt => opt.value === param.interfaceType);
        if (option) {
            option.disabled = true;
        }
    });
    },
    watch:{
       
    },
    // 编写计算属性
    computed: {
        /** 接口类型禁用操作  */
        interFaceTypes(index) {
            return this.interfaceTypes.map(option => ({
                ...option,
                disabled: this.form.paymentParams.some((item, idx) => idx !== index && item.interfaceType === option.value)
            }));

        },
        /** 自定义时间排序 */
        sortedGatewayList() {
            return this.gatewayList.slice().sort((a, b) => {
                return new Date(a.createTime) - new Date(b.createTime);
            });
        },
    },
    mounted() { },
    methods: {
        /** 自定义时间排序 */
        customSortMethod(a, b) {
            return new Date(a.createTime) - new Date(b.createTime);
        },

        
        /** 添加接口类型操作 */
        handleInterfaceTypeChange(value) {
            this.selectedInterfaceType=value
            // _value=this.selectedInterfaceType;
            // alert(_value)
            
            const newInterface = {
                interfaceType: value,
                interfaceUrl: '',
                httpMethod: '',
                contentType: '',
                httpAsyn: '',
                reqJson: '',
                resSyncJson: '',
                resAsyncJson: ''
            };

            // 如果接口类型是“请求网关token”，添加 tokenStorageLocation 字段
            if (value === '6' && value === '7') {
                newInterface.location = '';
            }
            this.form.paymentParams.push(newInterface);

            // 禁用已选择的接口类型--并且禁用掉前端拿到的数据
            this.interfaceTypes.forEach(opt => {
                opt.disabled = opt.value === value;
            });
          
            // 重置 selectedInterfaceType
            this.selectedInterfaceType = '';
        },

        OptionDisabled(value) {
            // 检查 form.paymentParams 中是否已经存在某个接口类型
            return this.form.paymentParams.some(param => param.interfaceType === value);
        },

        /** 删除接口类型 */
        removeInterface(index) {
            const option = this.interfaceTypes.find(opt => opt.value === this.form.paymentParams[index].interfaceType);
            if (option) {
                option.disabled = false;
            }

            this.form.paymentParams.splice(index, 1);
            // 重置 selectedInterfaceType
            this.selectedInterfaceType = "";
        },

        /** 获取接口类型的标签 */
        getInterfaceLabel(interfaceType) {
            const option = this.interfaceTypes.find(opt => opt.value === interfaceType);
            return option ? option.label : '未找到相应类型';
        },

        /**选择下拉框值后创建值 */
        handleOptionChange(value) {
            this.selectedOption = value;

            this.form.collectionParams.push([
                { interfaceType: value, code: '', value: '', location: '' }
            ]);

            // 重置 selectedOption
            // this.selectedOption = '';
        },

        /** 获取参数设置的标签 */
        getGatewayLabel(interfaceType) {
            const option = this.gatewaytableType.find(opt => opt.value === interfaceType);
            return option ? option.label : '未找到相应类型';
        },

        /** 下拉框禁用操作-- 禁用已选择的下拉框值，等点击删除后就解开下拉框的值  */
        isOptionDisabled(value) {
            return this.form.collectionParams.some(table => table.some(param => param.interfaceType === value));
        },

        /** 添加行 */
        addCollectionParam(index) {
            var _interfaceType = this.selectedOption;
            if  (this.form.collectionParams[index].length > 0 ) {
                _interfaceType = (this.form.collectionParams[index])[0].interfaceType;
               // 添加新行
            this.form.collectionParams[index].push({ interfaceType: _interfaceType,  code: '', value: '', location: '', });
            }
        },

        /** 删除单行 */
        removeCollectionParam(tableIndex, rowIndex) {
            if (rowIndex >= 0 && this.form.collectionParams[tableIndex] && this.form.collectionParams[tableIndex].length > 0) {
                if (this.form.collectionParams[tableIndex].length === 1) {
                    this.$message.warning('当前处于最后一行数据，无法删除');
                    return;
                }
                this.form.collectionParams[tableIndex].splice(rowIndex, 1);
            }
        },

        /** 删除整个表单模块  */
        removeTable(index) {
            this.form.collectionParams.splice(index, 1);
            if (this.form.collectionParams.length > 0) {
                this.selectedOption = this.form.collectionParams[this.form.collectionParams.length - 1][0].type;
            } else {
                this.selectedOption = '';
            }
        },

        handleTabClick(tab) {
            console.log('Clicked tab:', tab.name);
            // console.error(this.form.collectionParams);
            // console.error(this.form.paymentParams);
            // console.error(this.form.basicInfo);
        },

        /** 设置按钮显示 */
        shouldShowButtons() {
            return this.activeTab === 'paymentParams'|| this.activeTab === 'collectionParams';
        },

        /** 获取列表信息 */
        getList() {
            this.loading = true;
            const data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryVo: {
                    gatewayName: this.queryVo.gatewayName,
                    payType: this.queryVo.payType
                }
            };
            getChanelList(data).then(response => {
                this.gatewayList = response.rows;
                this.total = response.total;
                this.loading = false;
                // 初始数据
                // this.initializeForm(this.gatewayData);
            }).catch(error => {
                this.loading = false;
                console.error('获取列表数据失败:', error);
            });
        },

        /** 导出数据为 Excel 文件---导入需要接后端 */
        handleExport() {
            const data = this.gatewayList;
            if (!XLSX || !XLSX.utils) {
                console.error('XLSX 库未正确加载');
                return;
            }

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'gateways.xlsx');
        },

        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length!==1
        },

        /** 根据来查询列表数据 */
        handleQuery() {
            // console.log(this.queryVo)
            this.pageNum = 1;
            this.getList();
        },

        /** 重置按钮操作 */
        resetQuery() {
            this.queryVo.payType = '';
            this.queryVo.gatewayName = '';
            this.getList();
        },

        /** 重置表单内容 */
        reset() {
            this.form = {
                collectionParams: [],
                paymentParams: [],
            };
        },

        /** 提交基本信息、参数设置、接口设置的值 */
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // 比较当前表单数据与初始数据
                    // const hasChanges = !this.deepEqual(this.form, this.initialForm);

                    // 调用后端接口提交数据
                    // ,...this.interfaceData 
                    chanelAdd({ ...this.form}).then((response) => {
                        if (response.status === 200) {
                            this.$message.success('修改成功');
                            this.dialogVisible = false;
                            this.getList();
                        } else {
                            this.$message.success('修改成功');
                            this.dialogVisible = false;
                        }
                    }).catch((error) => {
                        // this.$message.error('修改失败: ' + (error.message || error));
                        // this.$message.error("value的值必须大于5个字符");
                        this.dialogVisible = false;
                    });
                }
            });
        },

        /**
        deepEqual(obj1, obj2) {
            if (obj1 === obj2) return true;

            if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
                return false;
            }

            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (const key of keys1) {
                if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }

            return true;
        },
         */

        /** 定义查看与修改操作 */
        async fetchGatewayInfo(row, isReadOnly = false,) {
            
            this.reset();
            this.title = isReadOnly ? '查看网关信息' : '修改网关信息';
            this.isReadOnly = isReadOnly;
            this.dialogVisible = true;

            try {
                const id = row.id || this.ids;
                const response = await chanelId(id);
                if (response) {
                    this.form.id = response.id;
                    this.form.gatewayId = response.gatewayId;
                    this.form.gatewayName = response.gatewayName;
                    this.form.gatewayType = response.gatewayType;
                    this.form.payType = response.payType;
                    this.form.remark = response.remark;
                    this.form.collectionParams = response.collectionParams;
                    this.form.paymentParams = response.paymentParams;

                    // if (response.paymentParams && response.paymentParams.length > 0) {
                    //     this.disabledCode = true;
                    //     this.disabledLocation = true;
                    // } else {
                    //     this.disabledCode = false;
                    //     this.disabledLocation = false;
                    // }
                } else {
                    this.$message.error('查询失败:' + response);
                   
                }
                this.loading = false;
                console.log('查询成功:', response);
            } catch (error) {
                this.loading = true;
            }
        },

        /** 查看列表详细操作 */
        async readAll(row) {
            this.fetchGatewayInfo(row, true); // 查看模式
        },

        /** 修改按钮调用接口 */
        async handleUpdate(row) {
            this.fetchGatewayInfo(row, false); // 修改模式

        },

        handleClose() {
            this.reset();
            // this.$refs.ruleForm.resetFields();
            this.dialogVisible = false
        },

        /** 取消按钮 */
        cancel() {
            this.dialogVisible = false;
            this.$refs.ruleForm.resetFields()
            this.reset();
        },

        /** 分页按钮操作 */
        handlePage(val) {
            this.pageNum = val;
            this.getList();
        },

        /** 分页按钮操作 */
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.handleQuery();
        }

    }
};
</script>

<style lang="less" scoped>
/* 样式定义 */
.common-table {
    margin-top: 8px;
    width: 100%;
    overflow-x: auto;
}

.buttons-container{
    margin-top: -5px;
}


::v-deep {
    .el-table__header-wrapper th {
        background-color: #F5F5F5 !important;
        color: black;
    }

    .el-table {
        font-size: 14px;
        color: #606266;
    }

    .el-table__header-wrapper .el-table{
        padding: 10px 0;
    }
}

.paper {
    position: relative;
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    float: right;
}
</style>
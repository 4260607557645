<template>
    <h2>{{ msg }}</h2>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            msg: '欢迎登录提交服系统'
        }
    }
}    
</script>


<style lang="less" scoped>
h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import User from '@/views/User'
import Main from '@/views/Main'
import Home from '@/views/Home'
import Login from '@/views/Login'
import Centre from '@/views/Centre.vue'
import Channel from '@/views/Channel.vue'
import Role from '@/views/Role.vue'

Vue.use(VueRouter)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: Main,
    name: 'Main',
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: 
        { breadcrumb: 
          [
            { label: '首页', path: '/home' }
          ] 
        }
      },
      {
        path: 'centre',
        name: 'centre',
        component: Centre,
        meta: 
        { 
          breadcrumb: [
            { label: '首页', path: '/home' }, 
            // { label: '个人信息', path: '/centre' },
            { label: '个人中心', path: '/centre' }
          ] 
        }
      },
      {
        path: 'user',
        name: 'user',
        component: User,
        meta: 
        { 
          breadcrumb: [
          { label: '首页', path: '/home' }, 
          { label: '测试数据', path: '/user' }
        ] 
      }
      },
      {
        path: 'channel',
        name: 'channel',
        component: Channel,
        meta: 
        { 
          breadcrumb: [
            { label: '首页', path: '/home' }, 
            { label: '渠道管理', path: '/gateways/channel'},
            { label: '网关列表', path: '/channel' },
          ] 
        }
      },
      {
        path: 'role',
        name: 'role',
        component: Role,
        meta: 
        { 
          breadcrumb: [
            { label: '首页', path: '/home' }, 
            { label: '系统配置', path: '/part/role' },
            { label: '角色管理', path: '/role' },
            // { label: '操作日志', path: '/operlog' },
          ]
         }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router

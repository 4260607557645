import Cookie from 'js-cookie'
export default {
    state: {
        isCollapse: false,
        tabsList: [{
            path: '/home',
            name: 'home',
            label: '首页',
            icon: 'potato-strips',
            url: 'Home/Home'
        }], // 面包屑数据
        menu: []
    },
    mutations: {
        addTab(state, tab) {
            const isExist = state.tabsList.find(t => t.name === tab.name);
            if (!isExist) {
                state.tabsList.push(tab);
            }
        },
        closeTag(state, tab) {
            const index = state.tabsList.findIndex(t => t.name === tab.name);
            if (index !== -1) {
                state.tabsList.splice(index, 1);
            }
        },
        // 修改菜单展开收起的状态
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse;
        },
        // 更新面包屑数据
        selectMenu(state, val) {
            console.log(val, 'val');
            if (val.name !== '/home') {
                const index = state.tabsList.findIndex(item => item.name === val.name);
                if (index === -1) {
                    state.tabsList.push(val);
                }
            }
        },
        // 删除指定的tag数据
        closeTag(state, item) {
            const index = state.tabsList.findIndex(val => val.name === item.name);
            state.tabsList.splice(index, 1);
        },
        // 设置menu的数据
        setMenu(state, val) {
            state.menu = val;
            Cookie.set("menu", JSON.stringify(val));
        },
        // 动态添加路由
        addMenu(state, router) {
            if (!Cookie.get("menu")) return;
            const menu = JSON.parse(Cookie.get("menu"));
            state.menu = menu;
            const menuArray = [];
            menu.forEach(parentItem => {
                if (parentItem.children) {
                    parentItem.children.forEach(childItem => {
                        childItem.component = () => import(`../views/${childItem.url}`);
                        menuArray.push(childItem);
                    });
                } else {
                    parentItem.component = () => import(`../views/${parentItem.url}`);
                    menuArray.push(parentItem);
                }
            });
            console.log(menuArray, "menuArray");

            menuArray.forEach(parentItem => {
                router.addRoute('Main', parentItem);
            });
        }
    },
    actions: {
        addTab({
            commit
        }, tab) {
            commit('addTab', tab);
        },
        closeTag({
            commit
        }, tab) {
            commit('closeTag', tab);
        }
    },
    getters: {

    }
};

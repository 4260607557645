<template>
    <div>
        <el-container>
            <el-aside width="auto">
                <common-aside />
            </el-aside>
            <el-container>
                <el-header>
                    <common-header />
                </el-header>
                <!-- <common-tag /> -->
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import CommonAside from '@/components/CommonAside.vue'
import CommonHeader from '@/components/CommonHeader.vue'
// import CommonTag from '@/components/CommonTag.vue'
export default {
    components: {
        CommonAside,
        CommonHeader,
        // CommonTag,
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.el-header {
    padding: 0;
}

.el-card__body,
.el-main {
    padding: 25px 20px;
    /* height: calc(100vh - 210px); */
}
</style>
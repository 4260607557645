import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import tab from './tab'
// import theme from './theme'
// import router from '@/router'
import user from './user' 

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tab,
    // theme,
    // router,
    user,
  }
})




import Cookie from 'js-cookie'
import axios from "axios";
import { Message } from 'element-ui'; 
import { getToken, setToken } from '@/utils/auth'

// 使用环境变量来区分不同环境下的API基础URL
const service = axios.create({
    baseURL: 'https://gateway2.aypaypro.com/api/',
    // baseURL: 'http://localhost:8300/',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    withCredentials: true
})


// 请求拦截器
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);


// 响应拦截器
service.interceptors.response.use(
    response => response.data,
    
    async error => {
        let errorMessage = '请求失败，请稍后重试';
        if (error.response) {
            const { status, data } = error.response;

            switch (status) {
                case 400:
                    errorMessage = '请求错误，请检查参数';
                    break;
                case 401:
                    errorMessage = '会话已过期，请重新登录';
                    if (data.message === 'Token has expired') {
                        // 尝试刷新 Token
                        return refreshToken().then(newToken => {
                            // 设置新的 Token 并重新发送请求
                            setToken(newToken);
                            error.config.headers['Authorization'] = `Bearer ${newToken}`;
                            return service(error.config);
                        }).catch(() => {
                            Message({
                                message: 'Token 已过期，请重新登录',
                                type: 'error',
                                duration: 5 * 1000
                            });
                            removeToken();
                            window.location.href = '/login';
                            return Promise.reject(error);
                        });
                    }
                    break;
                case 403:
                    errorMessage = '禁止访问，请联系管理员';
                    break;
                case 404:
                    errorMessage = '请求资源不存在';
                    break;
                case 500:
                    errorMessage = '服务器内部错误，请稍后再试';
                    break;
                default:
                    errorMessage = '请求发生错误，请稍后再试';
            }
        }
        
        Message.error(errorMessage);
        return Promise.reject(error);
    }
       
);
export default service
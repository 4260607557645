// store/modules/user.js
const userModule = {
    namespaced: true,
    state: {
      token: '',
      username: ''
    },
    mutations: {
      setUser(state, user) {
        state.token = user.token;
        state.username = user.username;
        localStorage.setItem('token', user.token); 
      },
      logout(state) {
        state.token = '';
        state.username = '';
        localStorage.removeItem('token');
      }
    },
    actions: {
      login({ commit }, user) {
        commit('setUser', user);
      },
      logout({ commit }) {
        commit('logout');
      }
    }
  };
  
  export default userModule;
import Vue, { isProxy } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './api/mock'
import { getToken, setToken } from '@/utils/auth'

Vue.config.productionTip = false
Vue.use(ElementUI)

// 使用全局前置导航守卫：
// 1、先获取token的值
// 2、在进行判断
router.beforeEach((to, from, next) => {
  const token = getToken()
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (token && to.name === 'login') {
    next({ name: 'home' })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  
  render: h => h(App)
}).$mount('#app')

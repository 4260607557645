<template>
  <div id="app">
    <!-- <transition name="fade" mode="out-in"> -->
      <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: "app"
}
</script>


<!-- background: lavender; -->
<style lang="less">
body,
html,
h3,
p {
  margin: 0;
  padding: 0;

}

/* 定义过渡动画类 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
<template>
    <div class="backgroundImg">
        <div ref="vantaRef" style="width: 100%; height: 100vh"> </div>
        <el-form :model="form" class="login-container" :rules="rules" ref="form">
            <h3 class="login_title">xxx后台管理系统</h3>

            <el-form-item label="账号" prop="username">
                <el-input v-model="form.username" clearable placeholder="请输入账号" prefix-icon="el-icon-user"
                    autocomplete="off">
                </el-input>
            </el-form-item>

            <!-- show-password -->
            <el-form-item label="密码" prop="password">
                <el-input  type="password" clearable v-model="form.password" placeholder="请输入密码"
                    prefix-icon="el-icon-lock" autocomplete="off">
                </el-input>
            </el-form-item>

            <!-- <el-form-item label="验证码" prop="code">
                <el-input v-model="form.code" placeholder="验证码" prefix-icon="el-icon-success" style="width: 100px;">
                </el-input>
                <el-button v-if="!picUrl" @click="getPicCode" type="primary" style="width: 100px;margin-left:2px"
                    @keyup.enter="submit(form)"> 获取验证码</el-button>
                <template v-else>
                    <img v-if="picUrl" :src="picUrl" @click="getPicCode" alt="">
                </template>
</el-form-item> -->


            <el-form-item class="isChecked">
                <!-- `checked` 为 true 或 false -->
                <el-checkbox v-model="form.checked" @change="handleRememberPwd" class="remeber_Pwd">记住密码</el-checkbox>
            </el-form-item>

            <el-form-item>
                <el-button @click="submit" class="login_button" type="primary" style="width: 100%;" :loading="loading">
                    登录
                </el-button>
                <!-- <el-button @click="cancel" style="margin-left:40px; margin-top: -2px;" type="danger">
                    重置
                </el-button> -->
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import { getToken, setToken } from '@/utils/auth'
import { mapActions } from 'vuex';
import { getUserLogin } from "../api/login"
export default {
    data() {
        return {
            // picUrl: '',
            // picKey: '',
            loading: false,
            forgetFormVisible: false,
            form: {
                username: '',
                password: '',
                // code: ''
            },
            rules: {
                // username: [
                //     { required: true, message: '请输入账号', trigger: 'blur' }
                // ],
                // password: [
                //     { required: true, message: '请输入密码', trigger: 'blur' }
                // ],
            }
        }
    },
    methods: {
        ...mapActions(['login']),

        handleRememberPwd(checked) {
            if (checked) {
                localStorage.setItem('username', this.form.username);
                localStorage.setItem('password', this.form.password);
            } else {
                localStorage.removeItem('username');
                localStorage.removeItem('password');
            }
        },
        cancel() {
            this.$refs.form.resetFields();
        },
        async submit() {
            this.loading = true;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const loginData = {
                        username: this.form.username,
                        password: this.form.password,
                    };

                    getUserLogin(loginData).then(res => {
                        const token = res;
                        if (token) {
                            setToken(token);
                            this.$router.push('/home');
                            this.$message.success("登录成功");
                        } else {
                            console.error('Login failed: Token not received');
                            this.$message.error("登录失败：未收到有效的令牌");
                        }
                    }).catch(error => {
                        console.error('Login failed:', error);
                        // this.$message.error("登录失败：请检查您的账号或密码");
                    });
                }
            });
        }
    }
}
</script>


<style lang="less" scoped>
.login_button {
    width: 100%;
}

.remeber_Pwd {
    margin-left: -25px;
}

.backgroundImg {
    position: relative;
    background: url('../assets/log/login_image.png') no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .login-container {
        width: 400px;
        border: 1px solid #eaeaea;
        padding: 35px 35px 20px 35px;
        background-color: #efefef;
        border-radius: 10px;
        box-shadow: 0 0 25px #efefef;
        box-sizing: border-box;
        z-index: 999;
        position: absolute;
        top: 34%;
        right: 5%;

        .el-input {
            width: 250px;
        }

        .isChecked {
            margin-left: 30px;
        }

        /* 改变input框背景颜色 */
        /deep/ .el-input__inner {
            background-color: #444444;
            color: white;
        }

        .login_title {
            text-align: center;
            color: #505458;
            margin: 0 auto 25px auto;
        }
    }
}
</style>
